import { get, post } from '@/utils/request'

export default {
  activeTipping: (query: any) => get(`/tipping/active`, query),
  closedTipping: (query: any) => get(`/tipping/closed`, query),
  activeCount: () => get(`/tipping/active-count`, null),
  closedCount: () => get(`/tipping/closed-count`, null),
  usernameCheck: (query: any) => get(`/tipping/username-check`, query),
  save: (query: any) => post(`/tipping/save`, query),
  checkJoin: () => get(`/tipping/check-join`, null),
}