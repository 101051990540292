<template>
  <div class="join-panel">
    <div class="panel-header flex">
      <div class="header-left flex">
        <h1 class="header-title flex">
          <div>{{ compDetail?.name }}</div>
          <div class="flex">
            <h1 class="link-text flex" style="font-size: 18px; line-height: 18px;">Prize Pool</h1>
            <h1 class="prize-text flex">{{ compDetail?.prizePool?.slice(0, -3) }}</h1>
          </div>
        </h1>
        
        <div class="left-bottom flex" style="padding: 0 20px;">
          <h1 class="lock-text">
            Closes in <span style="font-weight: 700;">{{ daysBetweenNow(new Date(compDetail?.startDate)) }}</span> days.
          </h1>
        </div>
        <div class="flex" style="padding: 0 20px; justify-content: space-between;">
          <div class="flex">
            <h1 class="link-text flex">Entry Fee</h1>
            <h1 class="lock-text flex">{{ compDetail?.entryFee }}</h1>
          </div>
          <div class="flex">
            <h1 class="link-text flex">System Fee</h1>
            <h1 class="lock-text flex">{{ compDetail?.systemFee }}%</h1>
          </div>
          <div class="flex">
            <h1 class="link-text flex">Admin</h1>
            <h1 class="lock-text flex">{{ compDetail?.admin?.username }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-content">
      <div class="steps">
        <el-steps :active="active" align-center finish-status="success">
          <el-step title="Details" />
          <el-step title="Confirm" />
          <el-step title="Paying" />
          <el-step title="Confirm" />
        </el-steps>
      </div>
      <el-scrollbar style="height: calc(100% - 90px);">
        <JoinForm v-if="active == 0" @joinEvent="handleJoin" :value="submitForm"></JoinForm>
        <JoinConfirmForm v-else-if="active == 1" @joinConfirmEvent="handleJoinConfirm" :value="submitForm.email"></JoinConfirmForm>
        <PaymentForm v-else-if="active == 2" @paymentEvent="handlePayment" :value="submitForm"></PaymentForm>
        <PaymentConfirmForm v-else-if="active == 3" :value="payForm"></PaymentConfirmForm>
      </el-scrollbar>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { daysBetweenNow, beforeDate } from '@/utils/date'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import JoinForm from '@/components/competition/join/JoinForm.vue'
import JoinConfirmForm from '@/components/competition/join/JoinConfirmForm.vue'
import PaymentForm from '@/components/competition/join/PaymentForm.vue'
import PaymentConfirmForm from '@/components/competition/join/PaymentConfirmForm.vue'

import competitionApi from '@/api/competition'
import tippingApi from '@/api/tipping'

const route = useRoute();
const router = useRouter();

const competitionId = ref();
const compDetail = ref();
const cidList:any = ref([]);

watch(
  () => route.params,
  () => {
    competitionId.value = route.params.competition;
    checkJoin();
  }
);

const submitForm:any = ref({});
const payForm = ref({});

const active = ref(0)

const handleJoin = (value: number, jForm: any) => {
  active.value += value;
  submitForm.value = { ...submitForm.value, ...jForm };

  // console.log(submitForm.value);
}

const handleJoinConfirm = (value: number) => {
  active.value += value;
  // console.log(submitForm.value);
}

const handlePayment = (value: number, pForm: any) => {
  active.value += value;
  payForm.value = { ...payForm.value, ...pForm };

  // console.log(payForm.value);
}

const checkJoin = () => {
  tippingApi.checkJoin().then(res => {
    if (res && res.code == 1 && res.response != null) {
      cidList.value = res.response;
      if (cidList.value.includes(parseInt(competitionId.value))) {
        router.push('/current-competition');
        ElMessage({
          type: 'error',
          message: 'Joined Competition',
        });
      }
    }
  }).catch(err => {

  });
}

onBeforeMount(() => {
  competitionId.value = route.params.competition;
  competitionApi.competitionDetail({id:competitionId.value}).then(res => {
    if (res && res.code == 1) {
      compDetail.value = res.response;
    }
  }).catch(err => {
    router.push('/current-competition');
    ElMessage({
      type: 'error',
      message: err,
    });
  });

  checkJoin();

});

</script>

<style>
/* .is-process .el-step__icon {
  color: #094067 !important;
  border-color: #094067 !important;
  border: 2px solid !important;
} */
.el-step__icon-inner {
  font-weight: 500 !important;
}
.el-step__icon {
  background-color: #EDEDED;
}
.is-process {
  color: #5200FF !important;
  border-color: #5200FF !important;
  font-weight: 500 !important;
}
.is-success {
  color: #5200FF !important;
  border-color: #5200FF !important;
  font-weight: 500 !important;
}
.is-success .el-step__icon {
  background-color: #5200FF;
  color: #F5F5F5 !important;
}
</style>

<style>
.join-panel .el-input__wrapper {
  background-color: transparent;
  border-radius: 10px;
  border: 0.5px solid #2E2E2E;
}
.join-panel .el-input__wrapper:hover {
  border: 0.5px solid #5200FF;
}
.join-panel .el-input__wrapper.is-focus {
  border: 0.5px solid #5200FF;
}
.join-panel .el-input {
  --el-input-focus-border-color: #5200FF !important;
}
.join-panel .el-input__inner {
  background-color: transparent;
}
.join-panel input:-webkit-autofill,
.join-panel input:-webkit-autofill:hover,
.join-panel input:-webkit-autofill:focus {
  border: 1px solid #dcdee2;
  -webkit-text-fill-color: #17233d;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>

<style scoped>
.prize-text {
  font-weight: 500;
  font-size: 35px;
  line-height: 35px;
  margin: 0;
  color: #5200FF;
}
.link-text {
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #8A8A8A;
  align-items: center;
  margin-right: 10px;
}
.join-panel {
  height: calc(100% - 30px);
  min-height: calc(650px - 30px);
  border-radius: 20px;
  background-color: #F5F5F5;
  padding: 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.steps {
  padding: 15px 0;
  border-bottom: 2px solid #D9D9D9;
  user-select: none;
}
.form-header {
  color: #5F6C7B;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.form-header-container {
  padding: 7.5px 15px;
  height: 20px;
  justify-content: space-between;
}
/* .submit-btn {
  width: 120px;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
} */
.panel-content {
  margin-top: 15px;
  background-color: #EDEDED;
  border-radius: 20px;
  height: calc(100vh - 156px - 50px - 30px - 115px - 30px);
  min-height: calc(650px - 30px - 115px - 30px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
/* .shift-btn {
  width: 80px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
} */
.left-bottom {
  /* height: 40px; */
  justify-content: space-between;
}
.lock-text {
  /* width: 100%; */
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #161616;
  margin: 0;
  align-items: center;
  text-align: left;
  /* padding: 0 20px; */
}
.header-left {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.header-title {
  /* height: 60px; */
  padding: 0 20px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  margin:0;
  color: #161616;
}
.panel-header {
  justify-content: space-between;
  width:100%;
  height: 115px;
  padding-bottom: 15px;
  border-bottom: 2px solid #EDEDED;
  user-select: none;
}
</style>