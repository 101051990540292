<template>
  <div class="panel-header-container flex">
    <h1 class="panel-header flex">
      Latest Result
    </h1>
  </div>
  <div class="panel-content-container">
    <div class="panel-content-header-container flex">
      <h1 class="panel-content-header flex">
        Team
      </h1>
      <h1 class="panel-content-header flex" style="width: 140px;">
        Streak
      </h1>
    </div>
    <el-scrollbar style="height: calc(100% - 30px); padding: 0 15px 0 30px;">
      <ResultRow v-for="item in data" :value="item"></ResultRow>
    </el-scrollbar>
    
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'

import ResultRow from '@/components/ladder/ResultRow.vue'

import gameApi from '@/api/game'

// const data = ref([
//   {
//     name:"COL",
//     streak:[1,1,-1,1,1]
//   },
//   {
//     name:"BRI",
//     streak:[-1,1,1,1,1]
//   },
//   {
//     name:"POR",
//     streak:[-1,-1,1,1,1]
//   },
//   {
//     name:"MEL",
//     streak:[1,1,-1,1,1]
//   },
//   {
//     name:"CAR",
//     streak:[1,1,1,1,-1]
//   },
//   {
//     name:"STK",
//     streak:[1,-1,1,1,-1]
//   },
//   {
//     name:"GWS",
//     streak:[1,-1,-1,1,1]
//   },
//   {
//     name:"SYD",
//     streak:[1,1,1,1,-1]
//   },
//   {
//     name:"WBD",
//     streak:[-1,-1,1,-1,1]
//   },
//   {
//     name:"ADE",
//     streak:[1,1,-1,-1,1]
//   },
//   {
//     name:"ESS",
//     streak:[-1,1,1,-1,-1]
//   },
//   {
//     name:"GEE",
//     streak:[-1,1,-1,-1,-1]
//   },
//   {
//     name:"RIC",
//     streak:[-1,-1,-1,1,-1]
//   },
//   {
//     name:"FRE",
//     streak:[1,-1,1,-1,1]
//   },
//   {
//     name:"GCS",
//     streak:[1,-1,-1,-1,-1]
//   },
//   {
//     name:"HAW",
//     streak:[-1,1,1,-1,-1]
//   },
//   {
//     name:"NOR",
//     streak:[-1,-1,-1,-1,1]
//   },
//   {
//     name:"WCE",
//     streak:[1,-1,-1,1,-1]
//   },
// ]);

const data = ref();

onBeforeMount(() => {
  gameApi.teamStreaks({page:0,size:5}).then(res => {
    if (res && res.code == 1) {
      data.value = res.response;
    }
  }).catch(err => {

  });
});
</script>

<style scoped>
.panel-content-header-container {
  height: 30px;
  background-color: #EDEDED;
  padding: 0 15px 0 30px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.panel-content-header {
  color: #1D1D1D;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: center;
  user-select: none;
}
.panel-content-container {
  margin-top: 10px;
  padding: 15px 0;
  height: calc(100% - 52.5px - 30px - 10px);
}
.panel-header-container {
  padding: 15px 30px 7.5px 30px;
  height: 30px;
  /* border-bottom: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.panel-header {
  color: #161616;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: center;
}
.panel-header-link {
  color: #5F6C7B;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  margin: 0;
  text-align: left;
  align-items: flex-end;
  cursor: pointer;
}
</style>